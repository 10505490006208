
.urban-toolbar{
    bottom: 4.5% !important;
    width: 65% !important;
    height: 10% !important;
}
.bg-front {
    position: fixed;
    top: 0;
    left:0;
    /* height: 100%; */
    width: 100%;
    /* filter: brightness(50%); */
    margin: 0;
    overflow: hidden;
    /* object-fit: cover; */
    /* pointer-events: none; */
    z-index: 0;
    /* filter: brightness(50%); */
  }

.toolbar-button {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 1% 2%;
    margin: 1%;
    border: none;
    width: 12%;
    font-size: max(10px, 1.042vw);
    /* border-radius: 10px; */
    font-family: var(--fontFamily1);
    color:var(--fontWhite);
    font-weight: 500;
    opacity: 0.8;
    cursor: pointer;

    background: var(--bgWhite);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    

    backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 12px;
}

.toolbar-button.selected {
    background: var(--bgDark);
    box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */
    opacity: 1;
    border-radius: 12px;
}

.toolbar-button.selected::before {
    content: '';
    position: absolute;
    top: -8.5px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(25, 74, 179, 0.85);
}

.triangle {
    position: relative;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid #007bff;
    margin-top: -2px;
}
