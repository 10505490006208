@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');

.App {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--bgWhite);
  margin: 0;
  top: 0;
  left: 0;
  overflow: hidden;
}
.backgroung-blur{
  filter: blur(3px);
  opacity: 0.5;
	transition: filter 0.8s ease-in;
}
.Loaded {
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--bgWhite);
  margin: 0;
  overflow: hidden;
}

.videoWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
}

.videoPlayer{
  display:none;
  height:0;
  width:0;
}
.video-transition-enter {
  transform: scale(0.1);
  opacity: 0;
}

.video-transition-enter-active {
  transform: scale(1);
  opacity: 1;
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
}

.video-transition-exit {
  transform: scale(1);
  opacity: 1;
}

.video-transition-exit-active {
  transform: scale(0.1);
  opacity: 0;
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
}


.bg {
  position: fixed;
  background-position: bottom;
  top: 0;
  left: 0;
  /* height: 100%; */
  width: 100%;
  /* filter: brightness(50%); */
  margin: 0;
  overflow: hidden;
  /* object-fit: cover; */
  /* pointer-events: none; */
  z-index: 0;
  /* filter: brightness(50%); */
}



.player {
  aspect-ratio: 16/9;
  position: absolute;
  width: 46.875%;
  top: 10%;
  left: 25%;
  opacity: 1;
  z-index: 1;
}


.bg::-webkit-media-controls-panel {
  display: none;
  opacity: 1;
}

.top-bar {
  position: absolute;
  width: 100%;
  height: 6.67%;
  left: 0px;
  top: 0px;

  background: var(--bgWhite);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(2px);
}

.dell-logo {
  position: absolute;
  width: 9.75%;
  height: 2.29%;
  left: 5.4167%;
  top: 1.3%;
}


/* Blur elements */

.blur-rect {
  position: absolute;
  width: 100%;
  height: 35%;
  /* margin: none; */
  bottom: 0%;

  background: linear-gradient(28.12deg, #0B2255 4.71%, rgba(11, 34, 85, 0) 30.32%);
} 


.blur-ell1 {
  /* Ellipse 1 */

  box-sizing: border-box;

  position: absolute;
  width: 52.9167%;
  height: 63.33%;
  left: -15.198%;
  top: 67.22%;

  background: var(--bgMedium);
  filter: blur(114px);
}

.blur-ell3 {
  /* Ellipse 3 */

  box-sizing: border-box;

  position: absolute;
  width: 28.75%;
  height: 42.87%;
  left: -12.865%;
  top: 67.22%;

  background: var(--bgMedium);
  filter: blur(114px);
}

.blur-ell4 {
  /* Ellipse 4 */

box-sizing: border-box;

position: absolute;
width: 34.53%;
height: 51.48%;
left: -9.22%;
top: 60.55%;

background: var(--bgMedium);
filter: blur(114px);
}

.um-head {
  /* Urban Mobility */

position: absolute;

left: 4.167%;
top: 82.33%;

font-family: var(--fontFamily1);
font-style: normal;
font-weight: 300;
font-size: 2.5vw;
line-height: 5svh;

/* identical to box height, or 112% */
letter-spacing: -0.06em;
text-transform: uppercase;

color: var(--fontWhite);
z-index: 3;
}

.um-sub {
  /* Technology is powering transformation in urban environment around the world */

position: absolute;
text-align: left;

left: 4.167%;
top: 91.63%;

width: 19.74%;

font-family: var(--fontFamily1);
font-style: normal;
font-weight: 500;
font-size: 0.833vw;
line-height: 2.222svh;

/* or 150% */

color: var(--fontWhite);
}

.nav-button {
  /* Rectangle 9 */

  position: absolute;

  /* width: 10.73%; */
  height: 4%;
  
  left: 4.167%;
  top: 80%;

  background: var(--bgLight);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(2px);
  padding: 7px 12px;
  border: none;

  /* < Edge City • Digital Cities */
  border-radius: 40px;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */

  font-family: var(--fontFamily1);
  font-style: normal;
  font-weight: 500;
  font-size: 0.833vw;
  color:var(--fontWhite);
  opacity: 0.8;
  cursor: pointer;
  z-index: 3;
}
.pulse{

  position: absolute;
  width: 10em;
  height: 4em;
  top: 50%;
  left: 50%;
  background-color: var(--bgWhite);
  border-radius: 50%;
  pointer-events: none;
  animation: pulse 2.6s ease infinite;
}
.mf-nav-button {
  overflow: hidden;
  /* Rectangle 9 */
  /* animation-name: backgroundColorPalette; */
	/* animation-duration: 5s; */
	/* animation-iteration-count: infinite; */
	/* animation-direction: alternate; */
	/* animation-timing-function: linear;  */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: absolute;

  width: 7.5%;
  height: 4%;
  
  left: 4.167%;
  top: 80%;

  background: var(--bgDark);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(2px);
  padding: 0;
  border: none;

  /* < Edge City • Digital Cities */
  border-radius: 40px;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */

  font-family: var(--fontFamily1);
  font-style: normal;
  font-weight: 500;
  font-size: 0.833vw;
  color:var(--fontWhite);
  opacity: 0.8;
  cursor: pointer;
  z-index: 3;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.1);
    opacity: 0;
}
10% {
    opacity: 1;
}
90% {
    transform: translate(-50%, -50%) scale(2.5);
    opacity: 0;
}
100% {
    transform: translate(-50%, -50%) scale(2.5);
    opacity: 0;
}
}
/* @keyframes backgroundColorPalette {
	0% {
		background: #0b0e21;
	}
	25% {
		background: #2c3b67;
	}
	50% {
		background: #1f315d;
	}
	75% {
		background: #13183c;
	}
	100% {
		background: #101c52;
	}
} */

.menu-button {
  box-sizing: border-box;

  position: absolute;
  width: 11.615%;
  height: 6.67%;
  /* left: 682px = 35.52%; offset -> 16px+width(223px) =  12.448%*/
  top: 84.44%;

  background: var(--bgWhite);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

  padding: 0;
  border: none;
  
  

  backdrop-filter: blur(2px);

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 12px;

  color: var(--fontWhite);
  text-align: center;
  font-family: var(--fontFamily1);
  font-weight: 500;
  font-size: 1vw;
  opacity: 0.8;
}

.active {
  opacity: 1;
  background: var(--bgDark);
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(2px);

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 12px;
}

.active-tri {
  /* Rectangle 3463173 */

  position: absolute;
  width: 0.9375%;
  height: 1.67%;
  left: 53.5%;
  top: 84%;

  background: var(--bgDark);
  border-radius: 1px;
  transform: rotate(-45deg);
}

.tour {
  left: 35.52%;
}

.watch {
  left: 47.968%;
}

.outcomes {
  left: 60.416%;
}

.bb {
  left: 72.864%;
}

.dvs {
  left: 85.312%;
}


.content-shadow-rect {
  /* Rectangle 11 */

  position: absolute;
  width: 43.54%;
  height: 41.57%;
  left: 29.167%;
  top: 34.44%;

  background: var(--bgShadow);
  opacity: 0.7;
  filter: blur(38px);
  border-radius: 12px;
}

.video-background{
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  overflow: hidden;
}
.UM-video-background{
  position: absolute;
  /* top: 50%; */
  left: 0;
  width: 100%;
  transform: translateY(0%);
  overflow: hidden;
  height: 100%;
}

.video-background video {
  position: absolute;
  top: 0;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  transform: translateX(-50%);
  z-index: -1;
  aspect-ratio: 21/9;
}

 .urban-nav-container {
    position: absolute;
      width: 100%;
      height: 100%;
      bottom: 6%;
}
.home-wrapper{
  z-index: 2 !important;
}

.manufacturing-bg-video{
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  /* aspect-ratio: 21/9; */
}
.UM-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  left: 0;
  overflow: hidden;
}

.enter-factory {
  height: 18%; /* Increase the height slightly */
  width: 20%; /* Increase the width slightly */
  right: 2%;
  position: absolute;
  bottom: 50px;
  display: flex;
  justify-content: flex-start; /* Align the icon and text to start */
  align-items: center;
  gap: 9%; /* Reduce gap for more text space */
  z-index: 9;
  font-size: 1.3vw; /* Increase font size for larger text */
  font-family: var(--fontFamily1);
  color: var(--fontWhite);
  font-weight: 300;
  opacity: 0.85;
  cursor: pointer;
  border-radius: 12px;
  background: var(--bgMedium);
}

.start-icon {
  width: 25%; /* Reduce the size of the icon */
  height: auto;
  padding-left: 10px;
}

.enter-text {
  text-align: left;
  flex-grow: 1; /* Allow the text to take more space */
}



#lock{ display:none; z-index: 0; }
@media screen and (orientation: portrait) {
#lock{ display:block; z-index: 1000;}
.App{ display:none !important;}
.wrapper{ display:none; }
}
@media screen and (orientation: landscape) {
#lock{ display:none;z-index: 0; }
.App{ display:block; }
.wrapper{ display:block; }
}