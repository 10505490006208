


.CarouselContainer{
    position: absolute;
    box-shadow: -55px 0px 28px -30px #00000066 !important;
    z-index: 11;
    /* box-shadow: -30px 0px 15px -10px #00000066 !important; */

    .left-container{
        animation: slideLeft ease .5s;
    }

    
    @keyframes slideLeft {
        0% {opacity:0; scaleX: 0 ; transform: translateX(100%);};
        10% {opacity: .05; scaleX: 0 ; transform: translateX(100%)};
        50% {opacity: .1; scaleX: .75 ; transform: translateX(75%)};    
        100% {opacity: 1; scaleX: 1 ; transform: translateX(0%)}
    }

}
.awssld__content{
    height: 84% !important;
    top: 8% !important;
    }
    .full-screen{
        position: absolute;
        bottom: 20px;
        z-index: 9;
        right: 10px;
    cursor: pointer;
    }
    .full-screen-img{
        position: absolute;
        z-index: 999;
    }
/* .awssld__content > img{
object-fit: contain !important;
} */
.fullscreen-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 10;
    cursor: pointer;
    /* background: rgba(0, 0, 0, 0.5); */
    border: none;
    padding: 10px;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.fullscreen-button:hover {
    /* background: rgba(0, 0, 0, 0.7); */
}

.fullscreen-button svg {
    width: 24px;
    height: 24px;
    /* No fill property */
}

.fullscreen-exit-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    /* background: rgba(255, 255, 255, 0.5); */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.fullscreen-exit-button:hover {
    /* background: rgba(255, 255, 255, 0.7); */
}

.fullscreen-exit-button svg {
    width: 25px;
    height: 25px;
    /* No fill property */
}

.background {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px); /* Adds a blur effect to the background */
    background: rgba(0, 0, 0, 0.1); /* Optional: Adds a semi-transparent dark overlay */
    z-index: 9999;
}
.fullscreen {
    position: fixed;
    top: 3.5vh;
    left: 10vw;
    width: 80vw;
    height: 93vh;
    /* background: rgba(0, 0, 0, 0.9); Dark backgroundq */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .fullscreen img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
   img {
    width: 100%;
  }
/* Fade Transition */
.media-enter {
    opacity: 1;
    animation: fadeIn ease-out .5s; /* Enter animation */
  }
  
  .media-exit {
    opacity: 0;
    animation: fadeOut ease-out .5s; /* Exit animation */
  }
  
  @keyframes fadeIn {
    0% {opacity:0};
    10% {opacity:0.2};
    50% {opacity:0.8};
    100% {opacity:1};
  }
  
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }

  