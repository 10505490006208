.cardcontainer {
  display: flex;
  justify-content: center;
  background-color: transparent;
  flex-wrap: wrap;
  height: 50%;
  width: 100%;
  margin-top: 5%;
}

.row {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 0%;
  gap: 2%;
}

.card-divider {
  border: none;
  width: 10%;
  color: var(--fontWhite);
  padding-top: 0.25%;
  padding-bottom: 0.25%;
  font-weight: bold;
  margin-left: 0
}


.card-heading {
  font-family: var(--CardsFontFamily);
  font-size: max(10px, var(--CardsFontSize));
  font-weight: var(--CardsFontWeight);
  margin: 0;
  margin-bottom: 0svh;
  padding-bottom: 1svh;
  text-align: left;
  left: 0;
  color: var(--CardsFontColor);
  animation: fadeIn ease 0.2s;
  -webkit-animation: fadeIn ease 0.2s;
	filter: brightness(150%);
}

.card-content {
  font-family: var(--CardsContentFontFamily);
  font-size: var(--CardsContentFontSize);
  text-align: left;
  font-weight: var(--CardsContentFontWeight);
  margin: 0;
  margin-top: 8%;
  padding: 0;
  color: var(--CardsFontColor);
  animation: fadeIn ease 0.2s;
  -webkit-animation: fadeIn ease 0.2s;
  line-height: var(--CardsContentLineHeight);
	filter: brightness(150%);
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

#container {
  width: 33.33%;
  height: 25svh;
  /* max-height: 20%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin: 1%; */
  margin-top: 5%;

}

.big-container {
	height: 29svh !important;
}

#panel,
#panel-container {
  width: 100%;
  /* height: 230px; */
}

#panel-container {
  width: 100%;
  /* position: absolute; */
  background-size: cover;
  /* transform-style: preserve-3d; */
  box-shadow: -1.875vw 1.25vw 1.75vw -0.625vw var(--bgShadow);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4%;
}

.App {
  text-align: center;
  color: var(--fontWhite);
  padding: 10px;
  background: var(--bgShadow);
  /* z-index: 1; */
  position: absolute;
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
}

.card-wrap {
  font-family: var(--fontFamily1);
  gap: 14px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 18%;
  top: 10%;
  /* max-width: 900px; */
}

.extra-margin-card {
  margin-top: 36px;
}

.nex-prev{
  fill: var(--bgNextPreButton);
}
.nex-prev-hover{
  fill:var(--bgNextPreButtonHover)
}

.card {
  --angle: 0deg;
  width: 17vw !important;
  animation: 4s rotate linear infinite;
  max-width: 364px;
  background: var(--bgCards);
  opacity: var(--CardsOpacity);
  text-align: left;
  height: 22svh;
  padding: 9%;
  border-radius: 4%;
}

.big-card {
	position: relative;
	height: 25svh;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.card-title {
  color: var(--fontWhite);
  font-size: 1.354vw;
  font-weight: 500;
	filter: brightness(150%);
}

.card-description {
  color: var(--fontToolbar);
  font-size: 0.938vw;
	filter: brightness(150%);
}

.card-divider {
  background: var(--bgSeparator);
  height: 1px;
  width: 42px;
  /* margin: 25px 0px 41px 0px; */
}

.current-card{
  box-shadow: 0 4px 8px rgba(192,192,192, 0.5), 
              0 7px 14px rgba(192,192,192, 0.8); 
  border: 2px solid #C0C0C0; 
  /* max-width: calc(364px + 36.4px); */
  }

.pagination-button {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  margin-left: 4%;
  margin-top: 5%;
  z-index: 99;
}


@media only screen and (max-width: 932px) {
  .extra-margin-card {
    margin: 0px;
  }
}


/* TooltipMenu.css */

.tooltip-container {
  position: absolute;
  display: flex;
	bottom: 5%;
  width: 65%;
}

.tooltip-button {
  color: var(--AchievedThroughButtonFontColor);
  border: none;
  /* padding: 10px 20px; */
  cursor: pointer;
	background: var(--bgAchievedThroughButton);
	padding: 5% 6%;
	/* padding: 9px 16px 9px 16px; */
	border-radius: 12px;
	display: flex; /* Enable flex layout */
  align-items: center; /* Vertically center content */
	font-size: var(--AchievedThroughButtonFontSize);
  font-family: var(--AchievedThroughButtonFontFamily);
  font-weight: var(--AchievedThroughButtonFontWeight);
  opacity: var(--AchievedThroughButtonOpacity);
}
.dive-deeper-arrow{
  stroke: var(--AchievedThroughButtonFontColor);
}
.right-arrow {
  width: 7px; /* Set the size of the right arrow image */
  /* height: 1vw; */
  margin-left: 5px; /* Add spacing between text and arrow */
}

.tooltip-content {
  display: block;
  position: absolute;
	bottom: 40px;
  color: var(--fontWhite);
	filter: brightness(150%);
  width: 230px;
  top: 50%;
  transform: translateY(50%);
  left: 75%;
	border: 2px solid;
	border-image-source: linear-gradient(41.48deg, #0F2C68 -1.61%, rgba(29, 63, 133, 0.84603) 7.93%, rgba(183, 206, 255, 0.6) 12.06%, rgba(20, 46, 100, 0.580451) 24.38%, rgba(0, 0, 0, 0) 60.34%);
	border-radius: 12px;
	z-index: 9 !important;
  background-color: var(--bgMedium) !important;
    font-family: var(--fontFamily1) !important;
    font-size: 16px !important;
    opacity: 0.8 !important;
    padding: 0px 8px !important;
    box-shadow: -30px 20px 25px -10px #00000066 !important;
}
.card-mapped-container .MuiPaper-elevation{
  background-color: var(--bgHamburgerMenuList) !important;
  color: var(--HamburgerMenuListFontColor) !important;
  border-radius: 0.75vw !important;
  font-family: var(--HamburgerMenuListFontFamily) !important;
  font-size:  max(10px, var(--HamburgerMenuListFontSize)) !important;

  opacity: var(--HamburgerMenuListOpacity) !important;
  padding: 0px 0.5vw !important;
  box-shadow: -1.875vw 1.25vw 1.75vw -0.625vw #00000066 !important;
}
.MuiButtonBase-root{
  padding: 1vh 1vw !important;
}
.card-mapped-container .MuiButtonBase-root:hover{
  background-color: var(--bgHamburgerMenuListHover) !important;
  border-radius: 0.3125vw;
}
.use-case-item {
	font-size: 1vw !important;
}
.tooltip-content ul {
	margin: 7%;
  list-style-type: none;
  padding: 0;
}

.tooltip-content li {
	font-size: 12px;
	margin: 5%;
  padding: 7px;
  text-align: left;
	background: var(--bgMedium);
	border: none;
	border-radius: 5px;
	color: var(--fontToolbar);
	cursor: pointer;
	font-family: var(--fontFamily1);
	font-weight: 700;
	letter-spacing: 0em;
	text-align: left;
	filter: brightness(150%);
}

.tooltip-container.active .tooltip-content {
  display: block;
}

.animationMenu-enter {
  opacity: 0;
}

.animationMenu-enter-active {
  opacity: 1;
  /* transition: opacity 150ms ease-out; */
	animation: animateGrow ease-out 0.225s;
}

.animationMenu-exit {
	opacity: 1;
}

.animationMenu-exit-active {
	opacity: 0;
  /* transition: opacity 150ms ease-out; */
	animation: animateExit ease-out 0.225s;
}

@keyframes animateGrow {
	0% {opacity:0; scale:0%;};
	25% {opacity: var(--HotspotHoverOpacity); scale:0%}
	100% {opacity: var(--HotspotHoverOpacity); scale:100%}
}

@keyframes animateExit {
	0% {opacity: var(--HotspotHoverOpacity); scale:100%}
	75% {opacity: var(--HotspotHoverOpacity); scale:0%}
	100% {opacity:0; scale:0%;};
	}